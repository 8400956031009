// bundle.service.ts

import { Injectable } from '@angular/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { BehaviorSubject, Observable } from 'rxjs';
import { BundleInfo } from './update.types';
import { StorageService } from './storage.service';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
// import { Dialog } from '@capacitor/dialog';

@Injectable({
    providedIn: 'root'
})
export class BundleService {
    private readonly CONFIG = {
        MAX_BUNDLE_AGE: 7 * 24 * 60 * 60 * 1000,  // 7 days
        BUILTIN_ID: 'builtin'
    };

    private currentBundleVersion = new BehaviorSubject<string>('0.0.0');

    constructor(
        private database: StorageService
    ) {}

    // Public Methods
    getCurrentBundleVersion(): Observable<string> {
        return this.currentBundleVersion.asObservable();
    }

    async getCurrentBundle(): Promise<BundleInfo | null> {
        try {
            const current = await CapacitorUpdater.current();
            return current.bundle;
        } catch (error) {
            console.error('Failed to get current bundle:', error);
            return null;
        }
    }

    // Bundle State Management
    async verifyBundleState(): Promise<void> {
        const VERIFICATION_TIMEOUT = 5000; // 5 seconds


        // try {
        //     const current = await CapacitorUpdater.current();
        //     const pendingBundle = await this.database.getPendingBundle();
            
        //     if (pendingBundle) {
        //         await this.handlePendingBundle(pendingBundle.bundle);
        //         return;
        //     }

        //     await this.verifyActiveBundle(current.bundle);
        // } catch (error) {
        //     console.error('Bundle state verification failed:', error);
        //     await this.resetToBuiltin();
        // }


        try {
            const verificationPromise = new Promise(async (resolve, reject) => {
                try {
                    const current = await CapacitorUpdater.current();
                    // await Dialog.alert({message:`[bundle service -> verificationPromise -> current] ${current}`});

                    const pendingBundle = await this.database.getPendingBundle();
                    // await Dialog.alert({message:`[bundle service -> verificationPromise -> pendingBundle] ${pendingBundle}`});

                    if (pendingBundle && pendingBundle.bundle) {

                        await this.handlePendingBundle(pendingBundle.bundle);
                    } else {
                        await this.verifyActiveBundle(current.bundle);
                    }
                    resolve(true);
                } catch (error) {

                    // await Dialog.alert({message:`[bundle service -> verificationPromise -> Failed] ${error}`});

                    reject(error);
                }
            });
    
            await Promise.race([
                verificationPromise,
                new Promise((_, reject) => 
                    setTimeout(() => reject(new Error('Bundle verification timeout')), 
                    VERIFICATION_TIMEOUT)
                )
            ]);
        } catch (error) {
            console.error('Bundle state verification failed:', error);
            // Only reset to builtin if absolutely necessary
            if (error.message !== 'Bundle verification timeout') {
                await this.resetToBuiltin();
            }
        }

        
    }

    private async handlePendingBundle(bundle: BundleInfo): Promise<void> {
        try {
            const platform = Capacitor.getPlatform();
        
            // Add iOS-specific delay
            if (platform === 'ios') {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }

            await this.markBundleAsInstalling(bundle.id);
            await this.activateBundle(bundle.id);

               // Ensure bundle is active before proceeding
            const current = await CapacitorUpdater.current();
            if (current.bundle.id !== bundle.id) {
                throw new Error('Bundle activation failed');
            }

            await CapacitorUpdater.notifyAppReady();
        } catch (error) {
            await this.markBundleAsInactive(bundle.id);
            console.error('Failed to handle pending bundle:', error);
            throw error;
        }
    }

    private async markBundleAsInstalling(bundleId: string): Promise<void> {
        await this.database.execute(
            'UPDATE bundles SET status = ? WHERE id = ?',
            ['installing', bundleId]
        );
    }

    private async markBundleAsInactive(bundleId: string): Promise<void> {
        await this.database.execute(
            'UPDATE bundles SET status = ? WHERE id = ?',
            ['inactive', bundleId]
        );
    }

    private async activateBundle(bundleId: string): Promise<void> {
        await CapacitorUpdater.set({ id: bundleId });
    }

    private async verifyActiveBundle(currentBundle: BundleInfo): Promise<void> {
        if (!currentBundle || !currentBundle.version) {
            console.error('Invalid bundle structure:', currentBundle);
            // await Dialog.alert({message:`[bundle service -> verifyActiveBundle -> Invalid bundle structure: ] ${currentBundle}`});
            throw new Error('Invalid bundle structure');
        }

        const activeBundle = await this.database.getActiveBundle();

        if (!activeBundle) {
            console.log('No active bundle found, storing current bundle info');
            // await Dialog.alert({message:`[bundle service -> verifyActiveBundle -> No active bundle found, storing current bundle info: ] ${currentBundle}`});

            await this.storeBundleInfo(currentBundle, 'active');

            // await Dialog.alert({message:`[bundle service -> verifyActiveBundle -> storeBundleInfo :  Done]`});
            this.updateCurrentBundleVersion('0.0.0');

            return;
        }
        // await Dialog.alert({message:`[bundle service -> verifyActiveBundle -> Active bundle: ] ${activeBundle}`});
        // await Dialog.alert({message:`[bundle service -> verifyActiveBundle -> Current bundle: ] ${currentBundle}`});



        this.updateCurrentBundleVersion('0.0.0');


        if (activeBundle?.version !== currentBundle?.version) {
            await this.handleBundleMismatch(currentBundle, activeBundle);
        }
    }

    private async handleBundleMismatch(currentBundle: BundleInfo, storedBundle: BundleInfo): Promise<void> {
        // await Dialog.alert({message:`[bundle service -> handleBundleMismatch  ] `});

        try {
            await this.storeBundleInfo(storedBundle, 'active');
            await this.addToUpdateHistory({
                bundleId: storedBundle.id,
                version: storedBundle.version,
                status: 'active',
                timestamp: new Date().toISOString()
            });
        } catch (error) {
            console.error('Bundle mismatch handling failed:', error);
            // await Dialog.alert({message:`[bundle service -> handleBundleMismatch ->  Bundle mismatch handling failed] ${error}`});

            await this.resetToBuiltin();
        }
    }

    // Bundle Storage
    async storeBundleInfo(bundle: BundleInfo, status: string): Promise<void> {
        try {
            if (status === 'active') {
                await this.deactivateCurrentBundles();
                this.updateCurrentBundleVersion(bundle.version);
            }

            await this.insertBundleRecord(bundle, status);
            
            if (status === 'active') {
                await this.updateActiveBundleState(bundle);
            }
        } catch (error) {
            console.error('Failed to store bundle info:', error);
            throw error;
        }
    }

    private async deactivateCurrentBundles(): Promise<void> {
        await this.database.execute(
            'UPDATE bundles SET status = ? WHERE status = ?',
            ['inactive', 'active']
        );
    }

    private async insertBundleRecord(bundle: BundleInfo, status: string): Promise<void> {
        const now = new Date().toISOString();
        await this.database.execute(
            `INSERT OR REPLACE INTO bundles (
                id, bundle_data, version, bundle_type, 
                timestamp, status, download_timestamp, install_timestamp
            ) VALUES (?, ?, ?, ?, ?, ?, ?, ?)`,
            [
                bundle.id,
                JSON.stringify(bundle),
                bundle.version,
                'release',
                now,
                status,
                status === 'downloading' ? now : null,
                status === 'active' ? now : null
            ]
        );
    }

    private async updateActiveBundleState(bundle: BundleInfo): Promise<void> {
        await this.database.setAppState('current_bundle', bundle);
    }

    private async updateCurrentBundleVersion(version: string) {


        const [appInfo, currentBundle] = await Promise.all([
            App.getInfo(),
            CapacitorUpdater.current()
        ]);

        // Set version based on bundle
        const CurrentVersion = currentBundle?.bundle?.id === 'builtin' 
        ? this.ensureSemverFormat(appInfo.version || '1.0.0')
        : this.ensureSemverFormat(currentBundle?.bundle?.version || appInfo.version || '1.0.0');

        this.currentBundleVersion.next(this.ensureSemverFormat(CurrentVersion));

    }

    // Bundle Maintenance
    async cleanupOldBundles(): Promise<void> {
        try {
            const { bundles } = await CapacitorUpdater.list();
            const current = await CapacitorUpdater.current();
            const now = Date.now();

            for (const bundle of bundles) {
                if (this.shouldDeleteBundle(bundle, current.bundle.id, now)) {
                    await this.deleteBundle(bundle);
                }
            }
        } catch (error) {
            console.error('Failed to cleanup bundles:', error);
        }
    }

    private shouldDeleteBundle(bundle: BundleInfo, currentBundleId: string, now: number): boolean {
        if (bundle.id === currentBundleId || bundle.id === this.CONFIG.BUILTIN_ID) {
            return false;
        }
        
        return bundle.downloaded && 
               now - new Date(bundle.downloaded).getTime() > this.CONFIG.MAX_BUNDLE_AGE;
    }

    private async deleteBundle(bundle: BundleInfo): Promise<void> {
        try {
            await CapacitorUpdater.delete(bundle);
            await this.database.execute(
                'DELETE FROM bundles WHERE id = ?',
                [bundle.id]
            );
        } catch (error) {
            console.error(`Failed to delete bundle ${bundle.id}:`, error);
        }
    }

    // Recovery
    async resetToBuiltin(): Promise<void> {
        // await Dialog.alert({message:'[bundle service -> resetToBuiltin] reseting app'});

        try {
            await CapacitorUpdater.reset();
            const current = await CapacitorUpdater.current();
            await this.storeBundleInfo(current.bundle, 'active');
            await this.cleanupOldBundles();
        } catch (error) {
            console.error('Reset to builtin failed:', error);
            throw new Error('Failed to reset to builtin bundle');
        }
    }

    // History Management
    private async addToUpdateHistory(entry: {
        bundleId: string;
        version: string;
        status: string;
        timestamp: string;
        error?: string;
    }): Promise<void> {
        try {
            await this.database.execute(
                `INSERT INTO update_history (
                    bundle_id, version, status, timestamp,
                    error, created_at
                ) VALUES (?, ?, ?, ?, ?, ?)`,
                [
                    entry.bundleId,
                    entry.version,
                    entry.status,
                    entry.timestamp,
                    entry.error || null,
                    new Date().toISOString()
                ]
            );
        } catch (error) {
            console.error('Failed to add update history:', error);
        }
    }

    // Utility Methods
    private ensureSemverFormat(version: string): string {
        const semverRegex = /^\d+\.\d+\.\d+$/;
        if (semverRegex.test(version)) {
            return version;
        }
        
        const parts = version.split('.').map(part => parseInt(part));
        while (parts.length < 3) {
            parts.push(0);
        }
        return parts.join('.');
    }
}