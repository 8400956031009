export class UpdatesUpgradeStatements {
    updatesUpgrades = [
      {
            toVersion: 1,
            statements: [
                `CREATE TABLE IF NOT EXISTS app_state (
                    key TEXT PRIMARY KEY,
                    value TEXT NOT NULL,
                    timestamp TEXT NOT NULL
                );`,
                `CREATE TABLE IF NOT EXISTS bundles (
                    id TEXT PRIMARY KEY,
                    bundle_data TEXT NOT NULL,
                    version TEXT NOT NULL,
                    update_id TEXT,
                    bundle_type TEXT NOT NULL,
                    timestamp TEXT NOT NULL,
                    status TEXT NOT NULL,
                    download_timestamp TEXT,
                    install_timestamp TEXT
                );`,
                `CREATE TABLE IF NOT EXISTS update_history (
                    id INTEGER PRIMARY KEY AUTOINCREMENT,
                    bundle_id TEXT NOT NULL,
                    version TEXT NOT NULL,
                    update_id TEXT,
                    status TEXT NOT NULL,
                    timestamp TEXT NOT NULL,
                    error TEXT,
                    details TEXT
                );`
            ]
        
      },
      
    ]
  }