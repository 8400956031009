// system.service.ts

import { Injectable } from '@angular/core';
import { App } from '@capacitor/app';
import { Device } from '@capacitor/device';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { StorageService } from './storage.service';
import { SystemInfo } from './update.types';

@Injectable({
    providedIn: 'root'
})
export class SystemService {
    private platform: string;
    private appId: string;

    constructor(
        private database: StorageService
    ) {}

    async initialize(appId: string, platform: string): Promise<void> {
        this.appId = appId;
        this.platform = platform;
    }

    isWebPlatform(): boolean {
        return this.platform === 'web';
    }

    async getSystemInfo(): Promise<SystemInfo> {
        try {
            const [appInfo, deviceInfo, currentBundle] = await Promise.all([
                App.getInfo(),
                Device.getInfo(),
                this.isWebPlatform() ? null : CapacitorUpdater.current()
            ]);

            // If we're on web platform, return web-specific info
            if (this.isWebPlatform()) {
                return {
                    appVersion: appInfo.version || '1.0.0',
                    platform: 'web',
                    bundleVersion: appInfo.version || '1.0.0',
                    deviceId: 'web-device',
                    app_id: this.appId,
                    device: {
                        manufacturer: deviceInfo.manufacturer,
                        model: deviceInfo.model,
                        operatingSystem: deviceInfo.operatingSystem,
                        osVersion: deviceInfo.osVersion,
                        platform: deviceInfo.platform,
                        webViewVersion: deviceInfo.webViewVersion,
                        isVirtual: deviceInfo.isVirtual
                    },
                    metrics: {
                        memoryUsage: deviceInfo.memUsed,
                    }
                };
            }
            const deviceId = await this.database.getDeviceId()
            // For mobile platforms, include bundle information
            return {
                appVersion: appInfo.version,
                platform: deviceInfo.platform,
                bundleVersion: currentBundle?.bundle?.version || appInfo.version,
                deviceId: deviceId,
                app_id: this.appId,
                bundle: currentBundle?.bundle ? {
                    id: currentBundle.bundle.id,
                    version: currentBundle.bundle.version,
                    downloaded: currentBundle.bundle.downloaded,
                    checksum: currentBundle.bundle.checksum
                } : undefined,
                device: {
                    manufacturer: deviceInfo.manufacturer,
                    model: deviceInfo.model,
                    operatingSystem: deviceInfo.operatingSystem,
                    osVersion: deviceInfo.osVersion,
                    platform: deviceInfo.platform,
                    webViewVersion: deviceInfo.webViewVersion,
                    isVirtual: deviceInfo.isVirtual
                },
                metrics: {
                    memoryUsage: deviceInfo.memUsed,
                },
                build: {
                    buildId: appInfo.build,
                    versionCode: appInfo.version,
                    versionName: appInfo.name
                }
            };
        } catch (error) {
            console.error('Failed to get system info:', error);
            // Return minimal info if error occurs
            return {
                appVersion: '1.0.0',
                platform: this.platform || 'unknown',
                bundleVersion: '1.0.0',
                deviceId: await this.database.getDeviceId(),
                app_id: this.appId
            };
        }
    }

    async checkAvailableStorage(): Promise<number> {
        try {
            if (navigator.storage && navigator.storage.estimate) {
                const estimate = await navigator.storage.estimate();
                return estimate.quota - estimate.usage;
            }
            return Number.MAX_SAFE_INTEGER;
        } catch (error) {
            console.error('Failed to check storage:', error);
            return Number.MAX_SAFE_INTEGER;
        }
    }

    isWebPlatformError(error: any): boolean {
        return error.message?.includes('Not implemented') || 
               error === 'CapacitorException: Not implemented on web.';
    }
}