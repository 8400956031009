import { Component, OnInit, Optional, inject } from '@angular/core';
import { Messaging, getToken, onMessage } from '@angular/fire/messaging';
import { EMPTY, from, Observable } from 'rxjs';
import { share, switchMap, tap } from 'rxjs/operators';
import { NgIf, AsyncPipe, JsonPipe, SlicePipe } from '@angular/common';
import { environment } from 'environment/environment';
import { UserService } from 'app/core/user/user.service';
import { NotificationsService } from '../notifications/notifications.service';
import {MatButtonModule} from '@angular/material/button';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@Component({
    selector: 'app-messaging',
    template: `

  `,
    styles: [],
    standalone: true,
    imports: [NgIf, AsyncPipe, JsonPipe, SlicePipe, MatButtonModule, MatSnackBarModule]
})
export class MessagingComponent implements OnInit {

  token$: Observable<any> = EMPTY;
  message$: Observable<any> = EMPTY;
  showRequest = false;
  private _snackBar = inject(MatSnackBar);


  constructor(@Optional() messaging: Messaging, private _notificationService:NotificationsService) {
    if (messaging) {
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker
            .register('firebase-messaging-sw.js', {type:'module'})
            .then((registration) => {
              // console.log('Service Worker registered with scope:', registration.scope);
            })
            .catch((error) => {
              // console.error('Service Worker registration failed:', error);
            });
        }
      this.token$ = from(
        
        navigator.serviceWorker.register('firebase-messaging-sw.js', { type: 'module'  }).
          then(serviceWorkerRegistration =>
            getToken(messaging, {
              serviceWorkerRegistration,
              vapidKey: environment.vapidKey,
            })
          ).catch(error=>console.log(error))).pipe(
            tap(token => {
              console.log('FCM', {token})
            }),
            share()
          )
      this.message$ = new Observable(sub => onMessage(messaging, it => sub.next(it))).pipe(
        tap(it => this.openSnackBar(it.notification.body, 'Got it') ),
      );
    }
  }


  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action);
  }
  ngOnInit(): void {
  this.message$.subscribe()
  this.token$.pipe(switchMap(token => this._notificationService.updateFCMToken(token))).subscribe()
  }

  request() {
    Notification.requestPermission();
  }

}
