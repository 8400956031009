@if (show) {
    <mat-progress-bar [mode]="mode" [value]="progress"></mat-progress-bar>
    
}


@if (cshow) {
    <mat-progress-bar [mode]="mode" [value]="progress"></mat-progress-bar>

        <div
        class="app-splash-screen z-99999"
        >
        <div class="app-splash-inner">
            <div class="app-logo"></div>
        </div>
     </div>
}
