export const environment = {
    useEmulators: false,
    production: true,
    firebase: {
        projectId:"pdcapp-acf0f",
        appId:"1:1015872408157:web:1dc8a65c344e371ad95bb4",
        storageBucket:"pdcapp-acf0f.appspot.com",
        apiKey:"AIzaSyACjT9Gih13reYWMW5X2-SEsMtETulWIzE",
        authDomain:"pdcapp-acf0f.firebaseapp.com",
        messagingSenderId:"1015872408157",
        measurementId:"G-9L9SMYBFER"
    },
    vapidKey: 'BPbNBXP4v31lGe6KeQSSnaCaQ_f4xyGgm_OVcRvIj-aaAvthNCX2Z_Xy2_2aEhSbLZuOLPXKq97LT-L_ba5PbZY',
    apiUrl:"https://api.pdcsafetrack.online",
    version:'1.8.0',
    appId: 'com.safteyreporter.pdc',
    debug: false


  };