import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { Injectable} from '@angular/core';
import { SQLiteService } from './sqlite.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DbnameVersionService } from './dbname-version.service';
import { UpdatesUpgradeStatements } from './upgrads/updates.upgrades.statement';
// import { Dialog } from '@capacitor/dialog';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  public updatesList: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  private databaseName: string = "";
  private uUpdStmts: UpdatesUpgradeStatements = new UpdatesUpgradeStatements();
  private versionUpgrades;
  private loadToVersion;
  private db!: SQLiteDBConnection;
  private isConnected = false;

  // private isUpdatesReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private sqliteService: SQLiteService,
  private dbVerService: DbnameVersionService) {
    this.versionUpgrades = this.uUpdStmts.updatesUpgrades;
    this.loadToVersion = this.versionUpgrades[this.versionUpgrades.length-1].toVersion;
  }
  async initializeDatabase(dbName: string) {
    console.log('db init called');
    try {
        this.databaseName = dbName;
        
        // Create upgrade statements
        await this.sqliteService.addUpgradeStatement({
            database: this.databaseName,
            upgrade: this.versionUpgrades
        });

        // Create and/or open the database
        this.db = await this.sqliteService.openDatabase(
            this.databaseName,
            false,
            'no-encryption',
            this.loadToVersion,
            false
        );

        this.dbVerService.set(this.databaseName, this.loadToVersion);
        
        // Test the connection
        const result = await this.db.query('SELECT 1 as test', []);
        this.isConnected = Boolean(result?.values?.[0]?.test);
        
        console.log('db initialized');
        return this.db;
    } catch (error) {
        console.error('Database initialization failed:', error);
        this.isConnected = false;
        throw error;
    }
}






    // async query<T>(sql: string, params: any[] = []): Promise<T[]> {
          
    //       try {
    //           const statement = this.prepareStatement(sql, params);
    //           console.log(this.db)
    //           const result = await this.db.query(statement, [], true);
    //           return result.values as T[];
    //       } catch (error) {
    //           console.error('Query failed:', sql, params, error);
    //           throw error;
    //       }
    //   }
  
      // async execute(sql: string, params: any[] = []): Promise<void> {
          
      //     try {
      //         const statement = this.prepareStatement(sql, params);
      //         await this.db.execute(statement, false, true);
      //     } catch (error) {
      //         console.error('Execute failed:', sql, params, error);
      //         throw error;
      //     }
      // }
  
      // private prepareStatement(sql: string, params: any[]): string {
      //     return sql.replace(/\?/g, (_, index) => {
      //         const value = params[index];
      //         // Handle null/undefined values
      //         if (value === null || value === undefined) {
      //           throw new Error(`Parameter at position ${index} cannot be null or undefined`);
      //       }


      //       // Handle different types
      //       if (typeof value === 'string') {
      //           return `'${value.replace(/'/g, "''")}'`;
      //       }
      //       if (typeof value === 'object') {
      //           return `'${JSON.stringify(value).replace(/'/g, "''")}'`;
      //       }
      //         return value.toString();
      //     });
      // }
  
      async transaction<T>(callback: (db: SQLiteDBConnection) => Promise<T>): Promise<T> {
          try {
              await this.db.execute('BEGIN TRANSACTION;', true);
              const result = await callback( this.db);
              await this. db.execute('COMMIT;', true);
              return result;
          } catch (error) {
              await  this.db.execute('ROLLBACK;', true);
              throw error;
          }
      }
  

      
 






  async setAppState(key: string, value: any): Promise<void> {
    const sql = `
        INSERT OR REPLACE INTO app_state (key, value, timestamp)
        VALUES (?, ?, ?)
    `;
    


     // Ensure value is never null by using an empty string or empty object
     const safeValue = value !== null && value !== undefined 
     ? JSON.stringify(value)
     : JSON.stringify({})


 await this.execute(sql, [
     key,
     safeValue.replace(/'/g, "''"),
     new Date().toISOString()
 ]);
}

async getAppState(key: string): Promise<any | null> {
    const results = await this.query<{value: string}>(
        'SELECT value FROM app_state WHERE key = ?',
        [key]
    );

    
    console.log(results)
    if (results && results.length > 0) {
        const value = results[0].value;
        // Handle the case where value might be stored as a string JSON object
        if (value === '{}' || value === 'null') {
            return null;
        }
        // If the value is stored as a JSON string, parse it
        try {
            const parsed = JSON.parse(value);
            return typeof parsed === 'string' ? parsed : null;
        } catch {
            // If it's not JSON, return the raw value if it's a string
            return typeof value === 'string' ? value : null;
        }
    }
    return null;
}


async cleanupDeviceIdStorage(): Promise<void> {
    try {
        // First, check if we have a malformed device ID
        const result = await this.query(
            'SELECT value FROM app_state WHERE key = ?',
            ['device_id']
        );

        if (result.values && result.values.length > 0) {
            const value = result.values[0].value;
            if (value === '{}') {
                // If we find an empty object, clear it
                await this.execute(
                    'UPDATE app_state SET value = NULL WHERE key = ?',
                    ['device_id']
                );
                console.log('Cleaned up malformed device ID');
            }
        }
    } catch (error) {
        console.error('Error cleaning up device ID storage:', error);
    }
}

async setFullAppState(state: any): Promise<void> {
    await this.setAppState('app_state', state);
}

async getFullAppState(): Promise<any | null> {
    return this.getAppState('app_state');
}

async getPlatform(): Promise<string> {
    try {
        // First try to get platform from app state
        const platformState = await this.getAppState('platform');
        if (platformState) {
            return platformState;
        }

        // If not in state, get from SQLite service
        const platform = this.sqliteService.platform;
        
        // Store it for future use
        await this.setAppState('platform', platform);
        
        return platform;
    } catch (error) {
        console.error('Failed to get platform:', error);
        // Default to web if there's an error
        return 'web';
    }
}


// Bundle Management
async storePendingBundle(bundleInfo: any): Promise<void> {
    const sql = `
        INSERT OR REPLACE INTO bundles (
            id, bundle_data, version, update_id, bundle_type, timestamp, status
        ) VALUES (?, ?, ?, ?, ?, ?, ?)
    `;
    
    await this.execute(sql, [
        bundleInfo.bundle.id,
        JSON.stringify(bundleInfo.bundle),
        bundleInfo.version,
        bundleInfo.updateId,
        'pending',
        new Date().toISOString(),
        'pending'
    ]);
}

async getPendingBundle(): Promise<any | null> {
    const results = await this.query(
        `SELECT * FROM bundles WHERE status = 'pending' ORDER BY timestamp DESC LIMIT 1`
    );

    if (results.length > 0) {
        const bundle:any = results[0];
        return {
            bundle: JSON.parse(bundle.bundle_data),
            version: bundle.version,
            updateId: bundle.update_id
        };
    }
    return null;
}

async setActiveBundle(bundleInfo: any): Promise<void> {
    // First deactivate any existing active bundles
    await this.execute(
        `UPDATE bundles SET status = 'inactive' WHERE status = 'active'`
    );

    // Then set the new active bundle
    const sql = `
        INSERT OR REPLACE INTO bundles (
            id, bundle_data, version, update_id, bundle_type, timestamp, status
        ) VALUES (?, ?, ?, ?, ?, ?, ?)
    `;
    
    await this.execute(sql, [
        bundleInfo.bundle.id,
        JSON.stringify(bundleInfo.bundle),
        bundleInfo.version,
        bundleInfo.updateId,
        'release',
        new Date().toISOString(),
        'active'
    ]);

    // Update app state
    await this.setAppState('current_bundle_version', bundleInfo.version);
}

async getActiveBundle(): Promise<any | null> {
    try{
        const results = await this.query(
            `SELECT * FROM bundles WHERE status = 'active' ORDER BY timestamp DESC LIMIT 1`
        );
    
        if (results && results.length > 0) {
            const bundle:any = results[0];
            try {
                const parsedData = JSON.parse(bundle.bundle_data);
                return {
                    bundle: parsedData,
                    version: bundle.version,
                    updateId: bundle.update_id
                };
            } catch (parseError) {
                console.error('Failed to parse bundle data:', parseError);
    
                // await Dialog.alert({message:`[storage service -> getActiveBundle -> Failed to parse bundle data: ] ${parseError}`});
    
                return null;
            }
        }
        return null;
    }
    catch (error) {
        console.error('Failed to get active bundle:', error);
        // await Dialog.alert({message:`[storage service -> getActiveBundle -> Failed to get active bundle:] ${error}`});
        return null;
    }

}

async removePendingBundle(): Promise<void> {
    await this.execute(`DELETE FROM bundles WHERE status = 'pending'`);
}

async clearAllBundles(): Promise<void> {
    await this.execute('DELETE FROM bundles');
}

// Update History Management
// async addToUpdateHistory(entry: any): Promise<void> {
//     const sql = `
//         INSERT INTO update_history (
//             bundle_id, version, update_id, status, timestamp, error, details
//         ) VALUES (?, ?, ?, ?, ?, ?, ?)
//     `;
    
//     await this.execute(sql, [
//         entry.bundleId,
//         entry.version,
//         entry.updateId || null,
//         entry.status,
//         entry.timestamp || new Date().toISOString(),
//         entry.error || null,
//         entry.details ? JSON.stringify(entry.details) : null
//     ]);

//     // Keep only recent history
//     await this.cleanupOldHistory();
// }

async getUpdateHistory(): Promise<any[]> {
    return this.query(
        'SELECT * FROM update_history ORDER BY timestamp DESC LIMIT 10'
    );
}

private async cleanupOldHistory(): Promise<void> {
    await this.execute(
        'DELETE FROM update_history WHERE id NOT IN (SELECT id FROM update_history ORDER BY timestamp DESC LIMIT 50)'
    );
}


// async setSystemInfo(info: {
//     appVersion: string;
//     platform: string;
//     bundleVersion: string;
//     deviceId: string;
// }): Promise<void> {
//    // Validate input
//    if (!info.appVersion || !info.platform || !info.bundleVersion || !info.deviceId) {
//     throw new Error('All system info fields are required');
// }

//     await this.execute(
//         `INSERT OR REPLACE INTO app_state (key, value, timestamp) VALUES (?, ?, ?)`,
//         [
//             'system_info',
//             JSON.stringify(info),
//             new Date().toISOString()
//         ]
//     );
// }

async getDeferUntil(): Promise<number | null> {
    const result = await this.query<{ value: string }>(
        `SELECT value FROM app_state WHERE key = 'update_defer_until'`
    );
    
    if (result.length > 0) {
        const value = JSON.parse(result[0].value);
        return value as number;
    }
    return null;
}

async getDeferCount(): Promise<number> {
    const result = await this.query<{ value: string }>(
        `SELECT value FROM app_state WHERE key = 'update_defer_count'`
    );
    
    if (result.length > 0) {
        const value = JSON.parse(result[0].value);
        return value as number;
    }
    return 0;
}


async setDeferCount(count: number): Promise<void> {
    await this.execute(
        `INSERT OR REPLACE INTO app_state (key, value, timestamp) VALUES (?, ?, ?)`,
        [
            'update_defer_count',
            JSON.stringify(count),
            new Date().toISOString()
        ]
    );
}

async setDeferUntil(timestamp: number): Promise<void> {
    await this.execute(
        `INSERT OR REPLACE INTO app_state (key, value, timestamp) VALUES (?, ?, ?)`,
        [
            'update_defer_until',
            JSON.stringify(timestamp),
            new Date().toISOString()
        ]
    );
}


// Device Management
async setDeviceId(deviceId: string): Promise<void> {
    await this.setAppState('device_id', deviceId);
}

async getDeviceId(): Promise<string | null> {
    return this.getAppState('device_id');
}

// Channel Management
async setDefaultChannel(channel: string): Promise<void> {
    await this.setAppState('default_channel', channel);
}

async getDefaultChannel(): Promise<string | null> {
    return this.getAppState('default_channel');
}

async getCurrentChannel(): Promise<string | null> {
    return this.getAppState('current_channel');
}

private validateInput(key: string, value: any): void {
  if (!key || typeof key !== 'string') {
      throw new Error('Key must be a non-empty string');
  }
  if (value === null || value === undefined) {
      throw new Error('Value cannot be null or undefined');
  }
}









private createDirectStatement(sql: string, params: any[]): string {
  let statement = sql;
  const values = params.map(param => {
      if (param === null || param === undefined) {
          return 'NULL';
      }
      if (typeof param === 'string') {
          return `'${param.replace(/'/g, "''")}'`;
      }
      if (typeof param === 'object') {
          return `'${JSON.stringify(param).replace(/'/g, "''")}'`;
      }
      return param;
  });

  // Replace each ? with the corresponding value
  for (const value of values) {
      statement = statement.replace('?', value);
  }
  return statement;
}



private prepareStatement(sql: string, params: any[]): string {
  return sql.replace(/\?/g, (_, index) => {
      const value = params[index];
      
      // For explicitly allowing NULL values in specific cases
      if (value === null || value === undefined) {
          return 'NULL';
      }

      // Handle different types
      if (typeof value === 'string') {
          return `'${value.replace(/'/g, "''")}'`;
      }
      if (typeof value === 'object') {
          return `'${JSON.stringify(value).replace(/'/g, "''")}'`;
      }
      if (typeof value === 'boolean') {
          return value ? '1' : '0';
      }
      if (typeof value === 'number') {
          return value.toString();
      }
      return `'${String(value).replace(/'/g, "''")}'`;
  });
}

async addToUpdateHistory(entry: any): Promise<void> {
 try {
            const timestamp = new Date().toISOString();
            const statement = `
                INSERT INTO update_history (
                    bundle_id, version, update_id, status, timestamp, error, details
                ) VALUES (
                    '${(entry.bundleId || 'system').replace(/'/g, "''")}',
                    '${(entry.version || '0.0.0').replace(/'/g, "''")}',
                    ${entry.updateId ? `'${entry.updateId.replace(/'/g, "''")}'` : 'NULL'},
                    '${(entry.status || 'unknown').replace(/'/g, "''")}',
                    '${timestamp}',
                    ${entry.error ? `'${entry.error.replace(/'/g, "''")}'` : 'NULL'},
                    ${entry.details ? `'${JSON.stringify(entry.details).replace(/'/g, "''")}'` : 'NULL'}
                )
            `;

            await this.db.execute(statement, false, true);
            await this.cleanupOldHistory();
        } catch (error) {
            console.error('Failed to add update history:', error);
            throw error;
        }
}

async setSystemInfo(info: {
  appVersion: string;
  platform: string;
  bundleVersion: string;
  deviceId: string;
}): Promise<void> {
  try {
      // Provide default values if any field is missing
      const safeInfo = {
          appVersion: info.appVersion || '1.0.0',
          platform: info.platform || 'unknown',
          bundleVersion: info.bundleVersion || '1.0.0',
          deviceId: info.deviceId || `device-${Date.now()}`
      };

      const statement = `
      INSERT OR REPLACE INTO app_state (key, value, timestamp)
      VALUES ('system_info', '${JSON.stringify(safeInfo).replace(/'/g, "''")}', '${new Date().toISOString()}')
  `;

  await this.db.execute(statement, false, true);
  console.log('System info set successfully');

  } catch (error) {
      console.error('Failed to set system info:', error);
      throw error;
  }
}

// async getDeviceId(): Promise<string> {
//   const result = await this.getAppState('device_id');
//   return result || `device-${Date.now()}`;
// }



// Add this helper method
private ensureNonNull<T>(value: T | null | undefined, defaultValue: T): T {
  return value !== null && value !== undefined ? value : defaultValue;
}


private async ensureDbConnection(): Promise<void> {
  if (!this.db || !this.isConnected) {
      console.log('Database connection not found, attempting to initialize...');
      await this.initializeDatabase(this.databaseName || 'updates_db');
  }

  try {
      // Test the connection using query instead of execute
      const result = await this.db.query('SELECT 1 as test', []);
      if (!result?.values?.[0]?.test) {
          throw new Error('Database connection test failed');
      }
      this.isConnected = true;
  } catch (error) {
      console.error('Database connection test failed:', error);
      this.isConnected = false;
      // Try to re-initialize
      await this.initializeDatabase(this.databaseName || 'updates_db');
  }
}


async query<T>(sql: string, params: any[] = []): Promise<T[]> {
  await this.ensureDbConnection();
  try {
      const statement = this.createDirectStatement(sql, params);
      console.log('Executing query:', statement);
      const result = await this.db.query(statement, []);
      return result.values as T[];
  } catch (error) {
      console.error('Query failed:', { sql, params, error });
      throw error;
  }
}

async execute<T>(sql: string, params: any[] = []): Promise<T[]> {
  await this.ensureDbConnection();
  try {
      const statement = this.createDirectStatement(sql, params);
      console.log('Executing SQL:', statement);
      const result = await this.db.execute(statement);
      return result.changes.values as T[];
  } catch (error) {
      console.error('Execute failed:', { sql, params, error });
      throw error;
  }
}

}