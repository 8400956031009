import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout, retryWhen, delay, take } from 'rxjs/operators';
import { environment } from 'environment/environment';
import { UpdateInfo, Channel, DeviceInfo } from './update.types';

@Injectable({
    providedIn: 'root'
})
export class UpdateHttpService {
    private readonly BASE_URL = `${environment.apiUrl}/updates`;
    private readonly TIMEOUT = 30000;
    private readonly RETRY_DELAY = 2000;
    private readonly MAX_RETRIES = 3;

    constructor(private http: HttpClient) {}

    checkForUpdate(params: any): Observable<any> {
        return this.http.post<UpdateInfo>(`${this.BASE_URL}/check`, {...params, mode: 'no-cors' }).pipe(
            timeout(this.TIMEOUT),
            this.addRetry(),
            catchError(this.handleError)
        );
    }

    registerDevice(params: any): Observable<any> {
        return this.http.post<void>(`${this.BASE_URL}/device`, {...params, mode: 'no-cors' }).pipe(
            timeout(this.TIMEOUT),
            this.addRetry(),
            catchError(this.handleError)
        );
    }

    getDevices(appId: string): Observable<any[]> {
        const params = new HttpParams().set('app_id', appId);
        return this.http.get<DeviceInfo[]>(`${this.BASE_URL}/device`, { params }).pipe(
            timeout(this.TIMEOUT),
            catchError(this.handleError)
        );
    }

    notifyInstallationStatus(params: any): Observable<any> {
        return this.http.post<void>(`${this.BASE_URL}/installation-status`, {...params, mode: 'no-cors' }).pipe(
            timeout(this.TIMEOUT),
            this.addRetry(),
            catchError(this.handleError)
        );
    }

    getChannels(appId: string): Observable<Channel[]> {
        const params = new HttpParams().set('app_id', appId);
        return this.http.get<Channel[]>(`${this.BASE_URL}/channel`, { params }).pipe(
            timeout(this.TIMEOUT),
            catchError(this.handleError)
        );
    }

    setChannel(params: any): Observable<any> {
        return this.http.post<void>(`${this.BASE_URL}/channel`, {...params, mode: 'no-cors' }).pipe(
            timeout(this.TIMEOUT),
            this.addRetry(),
            catchError(this.handleError)
        );
    }

    private addRetry() {
        return retryWhen(errors => 
            errors.pipe(
                delay(this.RETRY_DELAY),
                take(this.MAX_RETRIES)
            )
        );
    }

    private handleError(error: HttpErrorResponse) {
        let errorMessage = 'An error occurred';
        
        if (error.error instanceof ErrorEvent) {
            // Client-side error
            errorMessage = error.error.message;
        } else {
            // Server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }

        console.error('API Error:', error);
        return throwError(() => new Error(errorMessage));
    }

    // Add this method to the UpdateHttpService class
    checkDeviceRegistration(params: { device_id: string; app_id: string }): Observable<any> {
        const url = `${this.BASE_URL}/device`;
        return this.http.get(url, {params});
    }
}