// device-registration.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Device } from '@capacitor/device';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { FuseMockApiUtils } from '@fuse/lib/mock-api';
import { DeviceInfo } from './update.types';
import { UpdateHttpService } from './update-http.service';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class DeviceRegistrationService {
    private deviceId: string;
    deviceInfo: DeviceInfo;
    deviceRegistrationState = new BehaviorSubject<boolean>(false);

    constructor(
        private database: StorageService,
        private httpService: UpdateHttpService,

    ) {}

    async initialize(appId: string): Promise<void> {
        try {
            await this.initializeDeviceRegistration(appId);
        } catch (error) {
            console.error('Device registration initialization failed:', error);
            throw new Error(`Device registration failed: ${error.message}`);
        }
    }

    private async initializeDeviceRegistration(appId: string): Promise<void> {
        // Get or generate device ID
        this.deviceId = await this.getOrGenerateDeviceId();
        

        await this.database.setDeviceId(this.deviceId);

        // Get full device info
        this.deviceInfo = await this.collectDeviceInfo(this.deviceId, appId);

        // Verify/perform registration
        await this.verifyAndRegisterDevice(appId);
    }

    private async getOrGenerateDeviceId(): Promise<string> {
        let storedDeviceId = await this.database.getDeviceId();
        
        if (!storedDeviceId || storedDeviceId === '' || storedDeviceId === '{}') {
            storedDeviceId = await this.generateAndStoreNewDeviceId();
        }

        return storedDeviceId;
    }

    private async generateAndStoreNewDeviceId(): Promise<string> {
        const deviceInfo = await Device.getInfo();
        const newDeviceId = FuseMockApiUtils.guid();

        await this.database.setAppState('device_info', {
            id: newDeviceId,
            manufacturer: deviceInfo.manufacturer,
            model: deviceInfo.model,
            platform: deviceInfo.platform,
            osVersion: deviceInfo.osVersion,
            generated: new Date().toISOString()
        });

        await this.database.setDeviceId(newDeviceId);
        return newDeviceId;
    }

    private async collectDeviceInfo(deviceId: string, appId: string): Promise<DeviceInfo> {
        const [appInfo, deviceInfo, networkStatus] = await Promise.all([
            App.getInfo(),
            Device.getInfo(),
            Network.getStatus()
        ]);

        return {
            version_build: appInfo.version,
            plugin_version: appInfo.version,
            bundle_version: appInfo.build,
            platform: deviceInfo.platform,
            os_version: deviceInfo.osVersion,
            model: deviceInfo.model,
            manufacturer: deviceInfo.manufacturer,
            is_virtual: deviceInfo.isVirtual,
            device_id: deviceId,
            custom_id: deviceId,
            connection_type: networkStatus.connectionType,
            metadata: {
                appName: appInfo.name,
                deviceName: deviceInfo.name,
                webViewVersion: deviceInfo.webViewVersion,
                memUsed: deviceInfo.memUsed,

            },
            app_id: appId
        };
    }

    private async verifyAndRegisterDevice(appId: string): Promise<void> {
        if (!this.deviceInfo || !this.deviceId) {
            throw new Error('Device information not initialized');
        }

        let retryCount = 0;
        const maxRetries = 3;
        const retryDelay = 2000;

        while (retryCount < maxRetries) {
            try {
                const isRegistered = await this.verifyDeviceRegistration(appId);
                
                if (!isRegistered) {
                    await this.registerDevice();
                }

                this.deviceRegistrationState.next(true);
                return;

            } catch (error) {
                console.error(`Registration attempt ${retryCount + 1} failed:`, error);
                retryCount++;
                
                if (retryCount < maxRetries) {
                    await new Promise(resolve => setTimeout(resolve, retryDelay));
                } else {
                    throw new Error(`Device registration failed after ${maxRetries} attempts`);
                }
            }
        }
    }

    private async verifyDeviceRegistration(appId: string): Promise<boolean> {
        try {
            const response = await this.httpService.checkDeviceRegistration({
                device_id: this.deviceId,
                app_id: appId
            }).toPromise();
            
            return response !== null;
        } catch (error) {
            if (error === 'Device not found') {
                return false;
            }
            throw error;
        }
    }

    private async registerDevice(): Promise<void> {
        if (!this.deviceInfo) {
            throw new Error('Device information not available');
        }

        const response = await this.httpService.registerDevice(this.deviceInfo).toPromise();
        
        if (response?.status !== 'ok') {
            throw new Error('Device registration failed');
        }

        await this.database.setAppState('device_registration', {
            registered: true,
            timestamp: new Date().toISOString(),
            deviceInfo: this.deviceInfo,
            app_id: this.deviceInfo.app_id
        });
    }

    getDeviceId(): string {
        return this.deviceId;
    }

    getDeviceInfo(): DeviceInfo {
        return this.deviceInfo;
    }
}