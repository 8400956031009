import { Injectable } from '@angular/core';

import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection, CapacitorSQLitePlugin,
  capSQLiteUpgradeOptions, capSQLiteResult, capSQLiteValues} from '@capacitor-community/sqlite';

  @Injectable({
    providedIn: 'root'
})
export class SQLiteService {

    sqliteConnection!: SQLiteConnection;
    isService: boolean = false;
    platform!: string;
    sqlitePlugin!: CapacitorSQLitePlugin;
    native: boolean = false;
    private dbReady = false;

    constructor() {}

    private async checkWebPlatformRequirements(): Promise<void> {
      // Check if jeep-sqlite element exists
      const jeepSqliteEl = document.querySelector('jeep-sqlite');
      if (!jeepSqliteEl) {
          throw new Error('jeep-sqlite element not found in DOM. Please add it to your index.html');
      }

      // Check if SQL WASM files exist
      try {
          const response = await fetch('sql-wasm.wasm');
          if (!response.ok) {
              throw new Error('SQL WASM files not found. Please check your assets configuration.');
          }
      } catch (error) {
          throw new Error('Failed to load SQL WASM files: ' + error.message);
      }
  }




    /**
     * Plugin Initialization
     */
    async initializePlugin(): Promise<boolean> {

        try {
            this.platform = Capacitor.getPlatform();
            console.log('Platform detected:', this.platform);
            
            if (this.platform === 'ios' || this.platform === 'android') {
                this.native = true;
            }
          //   if (this.platform === 'web') {
          //     await this.checkWebPlatformRequirements();
          // }

            
            this.sqlitePlugin = CapacitorSQLite;
            this.sqliteConnection = new SQLiteConnection(this.sqlitePlugin);
            this.isService = true;

            // if (this.platform === 'web') {
            //     // For web platform, check and clean existing connections
            //     const result = await this.sqliteConnection.checkConnectionsConsistency();
            //     console.log('Connections consistency result:', result);
                
            //     const isConn = await this.sqliteConnection.isConnection("updates_db", false);
            //     if (isConn.result) {
            //         await this.sqliteConnection.closeConnection("updates_db", false);
            //     }
            // }

            return true;
        } catch (error) {
            console.error('Error initializing SQLite plugin:', error);
            throw error;
        }
    }

    async initWebStore(): Promise<void> {
        if (this.platform !== 'web') {
            return;
        }

        try {
            // Initialize the Web store
            await this.sqliteConnection.initWebStore();
            
            console.log('Web store initialized successfully');
            this.dbReady = true;
        } catch (error) {
            console.error('Error initializing web store:', error);
            throw new Error(`initWebStore failed: ${error.message || error}`);
        }
    }

    async openDatabase(dbName: string, encrypted: boolean, mode: string, version: number, readonly: boolean): Promise<SQLiteDBConnection> {
        try {
            if (this.platform === 'web' && !this.dbReady) {
                throw new Error('Web store not initialized. Call initWebStore first.');
            }

            let db: SQLiteDBConnection;
            const retCC = (await this.sqliteConnection.checkConnectionsConsistency()).result;
            const isConn = (await this.sqliteConnection.isConnection(dbName, readonly)).result;

            if (retCC && isConn) {
                db = await this.sqliteConnection.retrieveConnection(dbName, readonly);
            } else {
                db = await this.sqliteConnection.createConnection(
                    dbName,
                    encrypted,
                    mode,
                    version,
                    readonly
                );
            }

            await db.open();
            return db;
        } catch (error) {
            console.error('Error opening database:', error);
            throw error;
        }
    }

    async saveToStore(database: string): Promise<void> {
        if (this.platform === 'web') {
            try {
                await this.sqliteConnection.saveToStore(database);
                console.log('Database saved to store successfully');
            } catch (error) {
                console.error('Error saving to store:', error);
                throw error;
            }
        }
    }

    async closeConnection(database: string, readonly: boolean = false): Promise<void> {
        try {
            return await this.sqliteConnection.closeConnection(database, readonly);
        } catch (error) {
            console.error('Error closing connection:', error);
            throw error;
        }
    }

  async retrieveConnection(dbName:string, readonly: boolean): Promise<SQLiteDBConnection> {
    return await this.sqliteConnection.retrieveConnection(dbName, readonly);
  }

  async addUpgradeStatement(options:capSQLiteUpgradeOptions): Promise<void> {
    await this.sqlitePlugin.addUpgradeStatement(options);
    return;
  }


  async deleteDatabase(databaseName: string): Promise<void> {
    try {
        // For web platform
        if (this.platform === 'web') {
            await this.closeConnection(databaseName);
            // await this.deleteStore(databaseName);
            return;
        }

        // For mobile platforms
        if (!this.sqliteConnection) {
            throw new Error('SQLite plugin not initialized');
        }

        // First close any existing connections
        await this.closeConnection(databaseName);

        // Delete the database
       await this.sqlitePlugin.deleteDatabase({
            database: databaseName
        });

        // if (ret) {
        //     console.log(`Database ${databaseName} deleted successfully`);
        // } else {
        //     throw new Error(`Failed to delete database ${databaseName}`);
        // }

    } catch (error) {
        console.error(`Error deleting database ${databaseName}:`, error);
        throw error;
    }
}

}