// update.service.ts

import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { App } from '@capacitor/app';
import { Network } from '@capacitor/network';
import { Dialog } from '@capacitor/dialog';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { environment } from 'environment/environment';

import { UpdateStatus, UpdateInfo } from './update.types';
import { UpdateHttpService } from './update-http.service';
import { StorageService } from './storage.service';
import { SystemService } from './system.service';
import { BundleService } from './bundle.service';
import { DeviceRegistrationService } from './device-registration.service';

@Injectable({
    providedIn: 'root'
})
export class UpdateService implements OnDestroy {
    private static hasBeenDropped = false;

    private readonly CONFIG = {
        CHECK_INTERVAL: 3600000,                   // 1 hour
        MAX_DEFER_COUNT: 3,
        MIN_STORAGE_REQUIRED: 100 * 1024 * 1024,   // 100MB
        DEFAULT_CHANNEL: 'production'
    };

    private readonly UPDATE_STATES = {
        IDLE: 'idle',
        CHECKING: 'checking',
        DOWNLOADING: 'downloading',
        INSTALLING: 'installing',
        ERROR: 'error'
    } as const;

    // BehaviorSubjects
    private updateStatus = new BehaviorSubject<UpdateStatus>({ status: 'idle' });
    private currentChannel = new BehaviorSubject<string>(this.CONFIG.DEFAULT_CHANNEL);

    // Service state
    private appId: string;
    private updating = false;
    private networkConnected = true;
    private initialized = false;
    private checkInterval: any;


    constructor(
        private ngZone: NgZone,
        private httpService: UpdateHttpService,
        private database: StorageService,
        private systemService: SystemService,
        private bundleService: BundleService,
        private deviceService: DeviceRegistrationService

    ) {
           // Create instances with shared database
           this.systemService = new SystemService(database);
           this.bundleService = new BundleService(database);
           this.deviceService = new DeviceRegistrationService(database, this.httpService);
       }
    

    // Public API Methods
    getCurrentVersionObservable(): Observable<string> {
        return this.bundleService.getCurrentBundleVersion();
    }

    getUpdateStatus(): Observable<UpdateStatus> {
        return this.updateStatus.asObservable();
    }

    getCurrentChannel(): Observable<string> {
        return this.currentChannel.asObservable();
    }

    async checkForUpdates(): Promise<void> {
        return this.checkForUpdate('manual');
    }

    async setChannel(channel: string): Promise<void> {
        try {
            await this.httpService.setChannel({ 
                app_id: this.appId, 
                channel 
            }).toPromise();
            
            this.currentChannel.next(channel);
            await this.database.setAppState('current_channel', channel);
            await this.checkForUpdate('channelChange');
        } catch (error) {
            console.error('Failed to set channel:', error);
            throw error;
        }
    }

    // Initialization Methods
    async initialize(appId: string): Promise<void> {
        if (this.initialized) return;
        
        try {
            console.log('Starting initialization...');
            this.appId = appId;

            // Initialize all required services
            await this.initializeServices();
            // await Dialog.alert({message:'[update service] initializeServices'});

            await this.setupListeners();
            // await Dialog.alert({message:'[update service] setupListeners'});

            if (!this.systemService.isWebPlatform()) {
                await this.initializeUpdater();
                // await Dialog.alert({message:'[update service] initializeUpdater'});

            }

            this.initialized = true;
            await this.setInitializationStatus(true);
            // await Dialog.alert({message:'[update service] setInitializationStatus'});

            await this.checkForUpdate('initialize');
            // await Dialog.alert({message:'[update service] checkForUpdate'});


        } catch (error) {
            console.error('Initialization failed:', error);
            
            if (this.systemService.isWebPlatformError(error)) {
                console.log('Running on web platform, skipping updates');
                this.initialized = true;
                return;
            }

            await this.handleInitializationError(error);
        }
    }

    private async initializeServices(): Promise<void> {
        await this.database.initializeDatabase('updates_db');
        // await Dialog.alert({message:'[update service -> initializeServices -> database] initializeDatabase'});

        await this.deviceService.initialize(this.appId);
        // await Dialog.alert({message:'[update service -> initializeServices -> deviceService] initialize'});

        await this.systemService.initialize(this.appId, await this.database.getPlatform());
        // await Dialog.alert({message:'[update service -> initializeServices -> systemService] initialize'});

        
        // Load saved channel
        const savedChannel = await this.database.getAppState('current_channel');
        if (savedChannel) {
            this.currentChannel.next(savedChannel);
        }
        // await Dialog.alert({message:'[update service -> initializeServices -> savedChannel] savedChannel'});


    }

    private async initializeUpdater(): Promise<void> {
        await this.bundleService.verifyBundleState();
        // await Dialog.alert({message:'[update service -> initializeUpdater -> bundleService] verifyBundleState Done'});

        await this.setupAutoUpdate();

        // await Dialog.alert({message:'[update service -> initializeUpdater -> setupAutoUpdate] setupAutoUpdate Done'});

    }

    // Listener Setup Methods
    private async setupListeners(): Promise<void> {
        this.setupUpdateListeners();
        this.setupNetworkListeners();
        this.setupAppStateListeners();
    }

    private setupUpdateListeners(): void {
        CapacitorUpdater.addListener('download', (info) => {
            this.ngZone.run(() => {
                this.updateStatus.next({
                    status: this.UPDATE_STATES.DOWNLOADING,
                    progress: Math.round((info.percent || 0) * 100)
                });
            });
        });

        CapacitorUpdater.addListener('updateFailed', async () => {
            this.ngZone.run(async () => {
                await this.handleUpdateFailure();
            });
        });
    }

    private setupNetworkListeners(): void {
        Network.addListener('networkStatusChange', status => {
            this.networkConnected = status.connected;
            if (status.connected && this.initialized) {
                this.checkForUpdate('network').catch(console.error);
            }
        });
    }

    private setupAppStateListeners(): void {
        App.addListener('appStateChange', ({ isActive }) => {
            if (isActive && this.networkConnected) {
                this.checkForUpdate('appResume').catch(console.error);
            }
        });
    }

    // Update Check and Management
    private async checkForUpdate(source: string = 'manual'): Promise<void> {
        if (!await this.canPerformUpdate()) return;

        try {
            await this.logUpdateAttempt('check_initiated', { source });
            this.updateStatus.next({ status: this.UPDATE_STATES.CHECKING });

            if (await this.shouldSkipUpdate()) return;

            const updateInfo = await this.getUpdateInfo();
            if (!updateInfo) {
                this.updateStatus.next({ status: this.UPDATE_STATES.IDLE });
                return;
            }

            await this.handleUpdateAvailability(updateInfo);
        } catch (error) {
            console.error('Update check failed:', error);
            await this.handleUpdateError(error);
        }
    }

    private async canPerformUpdate(): Promise<boolean> {
        if (!this.initialized || this.systemService.isWebPlatform() || this.updating) {
            return false;
        }
        return true;
    }

    private async getUpdateInfo(): Promise<UpdateInfo> {

        const systemInfo = await this.systemService.getSystemInfo();

        console.log(systemInfo)
        return this.httpService.checkForUpdate({
            version: systemInfo.appVersion,
            bundleVersion: systemInfo.bundleVersion,
            platform: systemInfo.platform,
            app_id: this.appId,
            deviceId: systemInfo.deviceId,
            channel: this.currentChannel.getValue()
        }).toPromise();
    }

    private async handleUpdateAvailability(updateInfo: UpdateInfo): Promise<void> {
        if (updateInfo.forceUpdate) {
            await this.handleForceUpdate(updateInfo);
        } else {
            await this.handleOptionalUpdate(updateInfo);
        }
    }

    private async handleForceUpdate(updateInfo: UpdateInfo): Promise<void> {
        const { value } = await Dialog.confirm({
            title: 'Required Update',
            message: `A mandatory update (${updateInfo.version}) must be installed.\n\n${updateInfo.releaseNotes || ''}`,
            okButtonTitle: 'Update Now',
            cancelButtonTitle: 'Exit App'
        });

        if (value) {
            await this.performUpdate(updateInfo);
        } else {
            App.exitApp();
        }
    }

    private async handleOptionalUpdate(updateInfo: UpdateInfo): Promise<void> {
        const deferCount = await this.database.getDeferCount();
        const canDefer = deferCount < this.CONFIG.MAX_DEFER_COUNT;

        const { value } = await Dialog.confirm({
            title: 'Update Available',
            message: `Version ${updateInfo.version} is available. Update now?\n\n${updateInfo.releaseNotes || ''}`,
            okButtonTitle: 'Update Now',
            cancelButtonTitle: canDefer ? 'Later' : 'Exit App'
        });

        if (value) {
            await this.performUpdate(updateInfo);
        } else if (!canDefer) {
            App.exitApp();
        } else {
            await this.deferUpdate();
        }
    }

    private async performUpdate(updateInfo: UpdateInfo): Promise<void> {
        this.updating = true;
        try {
            this.setUpdateStatus('downloading', updateInfo);

            const bundle = await CapacitorUpdater.download({
                url: this.getUpdateDownloadUrl(updateInfo),
                version: updateInfo.version
            });

            await this.bundleService.storeBundleInfo(bundle, 'pending');
            await this.reloadApp();
        } catch (error) {
            console.error('Update failed:', error);
            await this.handleUpdateFailure(error);
        } finally {
            this.updating = false;
        }
    }

    // Auto-update and Maintenance
    private async setupAutoUpdate(): Promise<void> {
        await this.bundleService.cleanupOldBundles();
        
        if (this.checkInterval) {
            clearInterval(this.checkInterval);
        }
        
        this.checkInterval = setInterval(() => {
            if (this.networkConnected) {
                this.checkForUpdate('auto').catch(console.error);
            }
        }, this.CONFIG.CHECK_INTERVAL);
    }

    private async shouldSkipUpdate(): Promise<boolean> {
        const availableStorage = await this.systemService.checkAvailableStorage();
        if (availableStorage < this.CONFIG.MIN_STORAGE_REQUIRED) {
            throw new Error('Insufficient storage space');
        }

        const deferUntil = await this.database.getDeferUntil();
        return deferUntil ? Date.now() < deferUntil : false;
    }

    private async deferUpdate(): Promise<void> {
        const currentCount = await this.database.getDeferCount();
        await this.database.setDeferCount(currentCount + 1);
        await this.database.setDeferUntil(Date.now() + 24 * 60 * 60 * 1000); // 24 hours
    }

    // Error Handling
    private async handleUpdateFailure(error?: any): Promise<void> {
        const { value } = await Dialog.confirm({
            title: 'Update Failed',
            message: `error : ${error } , Would you like to restore the previous version?`,
            okButtonTitle: 'Yes',
            cancelButtonTitle: 'No'
        });

        if (value) {
            await this.bundleService.resetToBuiltin();
            await this.reloadApp();
        }
    }

    private async handleUpdateError(error: any): Promise<void> {
        console.error('Update error:', error);
        this.updateStatus.next({
            status: this.UPDATE_STATES.ERROR,
            error: 'Update failed. Please try again later.'
        });
        
        await Dialog.alert({
            title: 'Update Error',
            message: 'Failed to check for updates. Please try again later.'
        });
    }

    private async handleInitializationError(error: any): Promise<void> {
        console.error('Initialization error:', error);
        
        try {
            this.initialized = false;
            this.updateStatus.next({ status: this.UPDATE_STATES.ERROR });
            await this.setInitializationStatus(false, true);

            await this.bundleService.cleanupOldBundles();
            
            await Dialog.alert({
                title: 'Initialization Error',
                message: 'Failed to initialize update service. Please restart the app.'
            });

            await this.logUpdateAttempt('initialization_failed', {
                error: error.message,
                stack: error.stack
            });
        } catch (additionalError) {
            console.error('Error handler failed:', additionalError);
        }
    }

    // Utility Methods
    private getUpdateDownloadUrl(updateInfo: UpdateInfo): string {
        return `${environment.apiUrl}/updates${updateInfo.downloadUrl}`;
    }

    private async reloadApp(delay: number = 1000): Promise<void> {
        await new Promise(resolve => setTimeout(resolve, delay));
        await CapacitorUpdater.reload();
    }

    private setUpdateStatus(status: string, updateInfo?: any): void {
        let statusUpdate: UpdateStatus = {
            status: status as any
        };

        if (status === this.UPDATE_STATES.DOWNLOADING && updateInfo) {
            statusUpdate.progress = 0;
            statusUpdate.availableVersion = updateInfo.version;
        }

        if (status === this.UPDATE_STATES.ERROR && updateInfo) {
            statusUpdate.error = updateInfo.message || 'Update failed';
        }

        this.updateStatus.next(statusUpdate);
    }

    private async setInitializationStatus(initialized: boolean, needsReload: boolean = false): Promise<void> {
        await this.database.setAppState('init_status', {
            initialized,
            timestamp: new Date().toISOString(),
            needsReload
        });
    }

    private async logUpdateAttempt(phase: string, data: any): Promise<void> {
        try {
            const entry = {
                bundleId: data.bundleId || 'system',
                version: data.version || '0.0.0',
                updateId: data.updateId || null,
                status: phase,
                timestamp: new Date().toISOString(),
                error: data.error || null,
                details: data.details ? JSON.stringify(data.details) : JSON.stringify(data)
            };
    
            await this.database.addToUpdateHistory(entry);
            console.log(`Update ${phase}:`, data);
        } catch (error) {
            console.error('Failed to log update attempt:', error);
        }
    }

    // Cleanup
    ngOnDestroy(): void {
        if (this.checkInterval) {
            clearInterval(this.checkInterval);
        }

        this.removeAllListeners();
    }

    private removeAllListeners(): void {
        CapacitorUpdater.removeAllListeners();
        Network.removeAllListeners();
        App.removeAllListeners();
    }

    async cleanup(): Promise<void> {
        await this.setInitializationStatus(false);
        this.initialized = false;
        this.updateStatus.next({ status: this.UPDATE_STATES.IDLE });
        this.currentChannel.next(this.CONFIG.DEFAULT_CHANNEL);

        // Remove all listeners
        this.removeAllListeners();

        // Clear intervals
        if (this.checkInterval) {
            clearInterval(this.checkInterval);
            this.checkInterval = null;
        }

        // Reset flags
        this.updating = false;
        this.networkConnected = true;

        // Clear stored data
        await this.database.setAppState('current_channel', null);
        await this.bundleService.cleanupOldBundles();

        // Log cleanup
        await this.logUpdateAttempt('cleanup', {
            details: 'Service cleanup completed'
        });
    }
}