import { Capacitor } from '@capacitor/core';
import {  Component, NgZone } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Platform } from '@ionic/angular';
import { AsyncPipe, Location, NgIf } from '@angular/common';
import { FuseConfirmationService } from '@fuse/services/confirmation';
import { App , URLOpenListenerEvent} from '@capacitor/app';
import { KeyboardService } from './core/keyboard/keyboard.service';
import { InitializeAppService } from './core/liveUpdates/initialize.app.service';
import { StorageService } from './core/liveUpdates/storage.service';
import { SQLiteService } from './core/liveUpdates/sqlite.service';
// import { CapacitorUpdater } from '@capgo/capacitor-updater'
import { Toast } from '@capacitor/toast';
import { UpdateHttpService } from './core/liveUpdates/update-http.service';
import { DbnameVersionService } from './core/liveUpdates/dbname-version.service';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { UpdateService } from './core/liveUpdates/update.service';


import { Filesystem } from '@capacitor/filesystem';
import { environment } from 'environment/environment';
import { BundleService } from './core/liveUpdates/bundle.service';
import { SystemService } from './core/liveUpdates/system.service';
import { DeviceRegistrationService } from './core/liveUpdates/device-registration.service';
// import { Dialog } from '@capacitor/dialog';





// CapacitorUpdater.notifyAppReady()




@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet, AsyncPipe, NgIf],
    providers: [
      StorageService,
      {
          provide: UpdateService,
          useFactory: (
              ngZone: NgZone,
              httpService: UpdateHttpService,
              database: StorageService,
              systemService: SystemService,
              bundleService: BundleService,
              deviceService: DeviceRegistrationService
          ) => {
              return new UpdateService(
                  ngZone,
                  httpService,
                  database,
                  systemService,
                  bundleService,
                  deviceService
              );
          },
          deps: [
              NgZone,
              UpdateHttpService,
              StorageService,
              SystemService,
              BundleService,
              DeviceRegistrationService
          ]
      },
      SystemService,
      BundleService,
      DeviceRegistrationService,
      UpdateHttpService
  ]
})
export class AppComponent {
  updateStatus$ = this.updateService.getUpdateStatus();
  private initialized = false;

  constructor(
    private platform: Platform,
    private _location: Location,
    private _fuseConfirmationService: FuseConfirmationService,
    private router: Router,
    private updateService: UpdateService,
    private initializeAppService: InitializeAppService,
    private keyboardService: KeyboardService,
    private sqliteService: SQLiteService,
  ) {
    this.checkStoragePermissions()
    this.initializeApp();    
  }


  async  checkStoragePermissions() {
    // For Android 13+ (API 33+), you need to request specific permissions
    const permissions = await Filesystem.checkPermissions();
  
    if (permissions.publicStorage !== 'granted') {
      const request = await Filesystem.requestPermissions();
      
      if (request.publicStorage !== 'granted') {
        // Handle permission denied
        console.error('Storage permission is required');
      }
    }
  }
  


setupListeners(){
  
  document.addEventListener('deviceready', async () => {
    try {

      // Get current bundle info
      const current = await CapacitorUpdater.current();
      
      // Only set if not already set
      if (current.bundle.status !== 'success') {
        await CapacitorUpdater.set({
          id: current.bundle.id,
        });
  
        // Notify that app is ready after setting bundle
        await CapacitorUpdater.notifyAppReady();
      }
  
    } catch (error) {
      console.error('Update configuration failed:', error);
    }
  });
  
  // Add this to handle any state changes
  CapacitorUpdater.addListener('updateFailed', async () => {
    const current = await CapacitorUpdater.current();
    if (current.bundle.id) {
      await CapacitorUpdater.set({
        id: current.bundle.id,
      });
    }
  });

}

  async initService() {
    // await Dialog.alert({message:'service initialization started'});

    if (this.initialized){
      return
    }


    try {


        // Step 1: Platform ready
        await this.platform.ready();
        console.log('Platform is ready');
        const platform = Capacitor.getPlatform();


        console.log('initialized platform' , platform)




        if (platform === 'web') {
            this.initialized= true;
            console.log('not running on web ');
            return
        }

        await CapacitorUpdater.notifyAppReady();

        // await Dialog.alert({message:'app notified'});


        this.setupListeners()


        // Step 2: Database initialization
        await this.initializeAppService.initializeApp();
        console.log('Database initialized');
        // await Dialog.alert({message:'Database initialized'});


        // Step 3: Update service initialization with retry logic
        let retryCount = 0;
        const maxRetries = 3;

        while (retryCount < maxRetries) {
            try {
              if (this.sqliteService.platform !== 'web') {
                // await this.updateService.dropDatabaseOnce();

                await this.updateService.initialize(environment.appId);
                console.log('Update service initialized');
                // await Dialog.alert({message:'Database initialized'});
                break;
              }
            } catch (error) {
                retryCount++;
                console.error(`Initialization attempt ${retryCount} failed:`, error);
                
                if (retryCount === maxRetries) {
                    throw error;
                }
                
                // Wait before retrying
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }

    } catch (error) {
        console.error('Critical initialization error:', error);
        // Handle error appropriately
    }

}



//   // Add lifecycle hooks to handle the app state
// ionViewWillEnter() {
//   // This will run when the view is about to enter
//   this.initService().catch(console.error);
// }

ionViewWillLeave() {
  // Cleanup when leaving
  this.updateService.cleanup().catch(console.error);
}


  async ngOnInit() {
    await this.platform.ready();
    this.initService();

  }


  showStatusBar = async () => {
    await StatusBar.show();
  };
  
  private handleDeepLink(urlString: string) {
    const url = new URL(urlString);
    // Handle your deep link routing here
    this.router.navigateByUrl(url.pathname + url.search);
  }

  
  async initializeApp() {

    // await this.updateApp()

   // Handle deep linking
   App.addListener('appUrlOpen', (data: { url: string }) => {
    this.handleDeepLink(data.url);
  });


    this.platform.ready().then(async () => {

      const platform =  Capacitor.getPlatform();

      if (platform === 'web') return ;
      StatusBar.setBackgroundColor({ color: '#ffffff' });
      StatusBar.setOverlaysWebView({
        overlay: false,
      })
      StatusBar.setStyle({
        style: Style.Light,
      })
  
      this.showStatusBar();

      StatusBar.setBackgroundColor({ color: '#ffffff' });
      

      this.platform.backButton.subscribeWithPriority(10, (processNextHandler) => {
        console.log('Back press handler!');
        if (this._location.isCurrentPathEqualTo('/example')) {
          // Show Exit Alert!
          this.showExitConfirm();
          processNextHandler();
        } else {
          // Navigate to back page
          console.log('Navigate to back page');
          this._location.back();
        }
      });

    });

  }


  showExitConfirm(){
    const dialogRef = this._fuseConfirmationService.open({
      dismissible:false,
      title: 'App Exit',
      message: `Do you want to close the app?`,
      actions:{
      cancel:{
        label:'Stay'
      },
      confirm:{
        label:'Exit'
      }
      }
      
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        const exitApp = async () => {
          await App.exitApp();
        };

        exitApp()
      } else {
        // Submit without saving
        console.log('Application exit prevented!');
      }
    });
  }
 
}